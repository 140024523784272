import React from "react";
import { AppConsumer, AppContext } from "../components/AppContext";
import branch from '../img/branch.png';
import calendar from '../img/calendar.png';
import { IGenericProps } from "../interfaces/generics";
import { getNextRoute } from "../util/RouteHelper";
import { EModality } from "../interfaces/context";
import { toast } from "react-toastify";
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";
import withRouteProps from "../util/withRouteProps";



interface SelectModalityState {}

class SelectModality extends React.Component<IGenericProps, SelectModalityState> {
    constructor(props: IGenericProps) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.state = {
        }
    }

    goBack() {
        this.context.actions!.selectModality(undefined);
        this.props.history.goBack();
    }

    selectModality = async (modality: EModality) => {
        await this.context.actions!.selectModality(modality);
        this.props.history.push(getNextRoute(this.context));
    }

    componentDidMount() {
        const { t } = this.context;
        const ctxCompany = this.context.state.company;
        if (!ctxCompany || ctxCompany.name !== this.props.params.companyName) {
            console.error("Redirecting since no company was selected in SelectModality");
            if (!this.props.params.companyName)
                toast.error(t("SelectModality.TOAST_COMPANY_NOT_SELECTED"));
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
            return;
        }
    }

    componentDidUpdate() {
        const ctxCompany = this.context.state.company;
        if (ctxCompany?.name !== this.props.params.companyName) {
            console.warn("Company changed, redirecting");
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
            return;
        }
    }

    render() {
        return (
            <AppConsumer>
                {({ t }) => (
                    <>
                        <div className="list-selection form-card__content">
                            <div className="list-selection__content list-selection__content-options">
                                <TrackedButton
                                    id="modality_branch"
                                    className="list-selection__content list-selection__content-options-hover"
                                    onClick={this.selectModality.bind(this, EModality.branchesFirst)}>
                                    {t("SelectModality.BY_BRANCH")}
                                    <img src={branch} className="list-selection__content-modality" alt={t("SelectModality.ALT_BRANCH")} />

                                </TrackedButton>
                                <TrackedButton
                                    id="modality_date"
                                    className="list-selection__content list-selection__content-options-hover"
                                    onClick={this.selectModality.bind(this, EModality.dateFirst)}>
                                    {t("SelectModality.BY_DATE")}
                                    <img src={calendar} className="list-selection__content-modality" alt={t("SelectModality.ALT_DATE")} />

                                </TrackedButton>
                            </div>
                        </div>
                        <div className="list-selection__buttons">
                            <button className="list-selection__button list-selection__button-blue form-card__button form-card__button--previous"
                                onClick={this.goBack}>{t("SelectModality.PREVIOUS")}
                            </button>
                        </div>
                    </>
                )}
            </AppConsumer>
        )
    }

    static contextType = AppContext
    declare context: React.ContextType<typeof AppContext>;
}

export default withRouteProps(withTracker(SelectModality));
