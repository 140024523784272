import dayjs, { Dayjs } from "dayjs";

export const compareTimeZones = (timezone1 : string, timezone2:string, startDate:string|Dayjs, endDate:string|Dayjs): boolean => {

    if(!timezone1 || !timezone2 || !startDate || !endDate) {
        return false;
    }
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    
    for (let date = start; date.isBefore(end); date = date.add(1, 'days')) {
        const offset1 = dayjs.tz(date, timezone1).utcOffset();
        const offset2 = dayjs.tz(date, timezone2).utcOffset();
  
        if (offset1 !== offset2) {
            return false;
        }
    }
    
    return true;
}